@tailwind base;
@tailwind components;
@tailwind utilities;

html, body {
  @apply h-full;
}

body {
  @apply min-w-80;
}

.background {
  background-image: url('./images/google-earth-view-1216.jpg');
  @apply bg-repeat bg-cover bg-center bg-fixed;
  @apply w-full h-full;
  @apply absolute -z-10;
}

.content {
  @apply flex items-center;
  @apply min-h-full;

  font-family: 'Open Sans', sans-serif;
  @apply font-light;

  p {
    @apply mb-4;
  }
  p, ul li {
    @apply text-xl;
  }
  ul li {
    @apply mb-0.5;
    span {
      @apply px-5;
    }
    a {
      @apply text-zinc-800;
      @apply underline decoration-2;
    }
  }
  span {
    @apply inline-block;
    @apply bg-white;
    @apply px-1;
  }
}

.navbar {
  @apply flex flex-wrap justify-between items-center;
  @apply fixed top-0 left-0;
  a {
    @apply text-zinc-800;
  }
  & > .brand {
    font-family: 'Open Sans', sans-serif;
    @apply text-xl italic font-normal;
    @apply inline-block relative;
    @apply px-1 pt-1 pb-0.5;
    @apply mt-2;
    background: #ffffff8c;

    &:before {
      content: "";
      @apply bg-zinc-800;
      @apply block absolute left-0 bottom-0;
      @apply w-0;
      height: 3px;
      transition: width, background 0.5s;
    }
    &:hover:before {
      width: 100%;
      transition: width 0.5s;
    }
    &:after {
      content: "";
      @apply bg-zinc-800;
      @apply block absolute right-0 bottom-0;
      @apply w-0;
      height: 3px;
      transition: width 0.5s ease-in;
    }
    &:hover:after {
      background: none;
      width: 100%;
      transition: width, background ease-in;
    }
  }
}

.attributions {
  @apply fixed bottom-0 top-auto;
  @apply w-full;
  @apply text-xs;
  background: rgba(255, 255, 255, 0.55);

  ul {
    &:after {
      content: "";
      @apply block clear-both;
    }

    li {
      @apply px-2;
    }
  }
  a {
    @apply underline;
  }
  .show-md {
    @apply hidden sm:inline-block;
  }
}

navbar > .social {
  float: right;
}

.social {
  @apply fixed top-auto right-0 bottom-4 sm:top-0 sm:bottom-auto;
  @apply py-2 px-4;

  ul {
    @apply float-right;
    @apply flex flex-row sm:flex-col;

    &:after {
      content: "";
      @apply block clear-both;
    }
  }

  li {
    @apply ml-4 my-2;
  }

  a {
    @apply text-zinc-800 sm:text-zinc-200;
    svg {
      height: 1.25rem;
      /* @apply h-5; */
    }
    svg#email {
      /* @apply h-5; */
      height: 1.10rem;
    }
  }
}
