*, :before, :after {
  box-sizing: border-box;
  border: 0 solid #e5e7eb;
}

:before, :after {
  --tw-content: "";
}

html, :host {
  -webkit-text-size-adjust: 100%;
  tab-size: 4;
  font-feature-settings: normal;
  font-variation-settings: normal;
  -webkit-tap-highlight-color: transparent;
  font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Noto Sans, Ubuntu, Cantarell, Helvetica Neue, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
  line-height: 1.5;
}

body {
  line-height: inherit;
  margin: 0;
}

hr {
  color: inherit;
  border-top-width: 1px;
  height: 0;
}

abbr:where([title]) {
  text-decoration: underline dotted;
}

h1, h2, h3, h4, h5, h6 {
  font-size: inherit;
  font-weight: inherit;
}

a {
  color: inherit;
  -webkit-text-decoration: inherit;
  text-decoration: inherit;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp, pre {
  font-feature-settings: normal;
  font-variation-settings: normal;
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, Liberation Mono, Courier New, monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

table {
  text-indent: 0;
  border-color: inherit;
  border-collapse: collapse;
}

button, input, optgroup, select, textarea {
  font-feature-settings: inherit;
  font-variation-settings: inherit;
  font-family: inherit;
  font-size: 100%;
  font-weight: inherit;
  line-height: inherit;
  letter-spacing: inherit;
  color: inherit;
  margin: 0;
  padding: 0;
}

button, select {
  text-transform: none;
}

button, input:where([type="button"]), input:where([type="reset"]), input:where([type="submit"]) {
  -webkit-appearance: button;
  background-color: #0000;
  background-image: none;
}

:-moz-focusring {
  outline: auto;
}

:-moz-ui-invalid {
  box-shadow: none;
}

progress {
  vertical-align: baseline;
}

::-webkit-inner-spin-button {
  height: auto;
}

::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

summary {
  display: list-item;
}

blockquote, dl, dd, h1, h2, h3, h4, h5, h6, hr, figure, p, pre {
  margin: 0;
}

fieldset {
  margin: 0;
  padding: 0;
}

legend {
  padding: 0;
}

ol, ul, menu {
  margin: 0;
  padding: 0;
  list-style: none;
}

dialog {
  padding: 0;
}

textarea {
  resize: vertical;
}

input::-moz-placeholder {
  opacity: 1;
  color: #9ca3af;
}

input::placeholder {
  opacity: 1;
  color: #9ca3af;
}

textarea::-moz-placeholder {
  opacity: 1;
  color: #9ca3af;
}

textarea::placeholder {
  opacity: 1;
  color: #9ca3af;
}

button, [role="button"] {
  cursor: pointer;
}

:disabled {
  cursor: default;
}

img, svg, video, canvas, audio, iframe, embed, object {
  vertical-align: middle;
  display: block;
}

img, video {
  max-width: 100%;
  height: auto;
}

[hidden] {
  display: none;
}

*, :before, :after {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position: ;
  --tw-gradient-via-position: ;
  --tw-gradient-to-position: ;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #3b82f680;
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
  --tw-contain-size: ;
  --tw-contain-layout: ;
  --tw-contain-paint: ;
  --tw-contain-style: ;
}

::-ms-backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position: ;
  --tw-gradient-via-position: ;
  --tw-gradient-to-position: ;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #3b82f680;
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
  --tw-contain-size: ;
  --tw-contain-layout: ;
  --tw-contain-paint: ;
  --tw-contain-style: ;
}

::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position: ;
  --tw-gradient-via-position: ;
  --tw-gradient-to-position: ;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #3b82f680;
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
  --tw-shadow: 0 0 #0000;
  --tw-shadow-colored: 0 0 #0000;
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
  --tw-contain-size: ;
  --tw-contain-layout: ;
  --tw-contain-paint: ;
  --tw-contain-style: ;
}

html, body {
  height: 100%;
}

body {
  min-width: 20rem;
}

.background {
  z-index: -10;
  background-image: url("google-earth-view-1216.0b86d246.jpg");
  background-position: center;
  background-repeat: repeat;
  background-size: cover;
  background-attachment: fixed;
  width: 100%;
  height: 100%;
  position: absolute;
}

.content {
  align-items: center;
  min-height: 100%;
  font-family: Open Sans, sans-serif;
  font-weight: 300;
  display: -ms-flexbox;
  display: flex;
}

.content p {
  margin-bottom: 1rem;
}

.content p, .content ul li {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.content ul li {
  margin-bottom: .125rem;
}

.content ul li span {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}

.content ul li a {
  --tw-text-opacity: 1;
  color: rgba(39, 39, 42, var(--tw-text-opacity));
  text-decoration-line: underline;
  text-decoration-thickness: 2px;
}

.content span {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  padding-left: .25rem;
  padding-right: .25rem;
  display: inline-block;
}

.navbar {
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  display: -ms-flexbox;
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
}

.navbar a {
  --tw-text-opacity: 1;
  color: rgba(39, 39, 42, var(--tw-text-opacity));
}

.navbar > .brand {
  background: #ffffff8c;
  margin-top: .5rem;
  padding: .25rem .25rem .125rem;
  font-family: Open Sans, sans-serif;
  font-size: 1.25rem;
  font-style: italic;
  font-weight: 400;
  line-height: 1.75rem;
  display: inline-block;
  position: relative;
}

.navbar > .brand:before {
  content: "";
  --tw-bg-opacity: 1;
  background-color: rgba(39, 39, 42, var(--tw-bg-opacity));
  width: 0;
  height: 3px;
  transition: width, background .5s;
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
}

.navbar > .brand:hover:before {
  width: 100%;
  transition: width .5s;
}

.navbar > .brand:after {
  content: "";
  --tw-bg-opacity: 1;
  background-color: rgba(39, 39, 42, var(--tw-bg-opacity));
  width: 0;
  height: 3px;
  transition: width .5s ease-in;
  display: block;
  position: absolute;
  bottom: 0;
  right: 0;
}

.navbar > .brand:hover:after {
  background: none;
  width: 100%;
  transition: width, background ease-in;
}

.attributions {
  background: #ffffff8c;
  width: 100%;
  font-size: .75rem;
  line-height: 1rem;
  position: fixed;
  top: auto;
  bottom: 0;
}

.attributions ul:after {
  content: "";
  clear: both;
  display: block;
}

.attributions ul li {
  padding-left: .5rem;
  padding-right: .5rem;
}

.attributions a {
  text-decoration-line: underline;
}

.attributions .show-md {
  display: none;
}

@media (width >= 640px) {
  .attributions .show-md {
    display: inline-block;
  }
}

navbar > .social {
  float: right;
}

.social {
  position: fixed;
  top: auto;
  bottom: 1rem;
  right: 0;
}

@media (width >= 640px) {
  .social {
    top: 0;
    bottom: auto;
  }
}

.social {
  padding: .5rem 1rem;
}

.social ul {
  float: right;
  -ms-flex-direction: row;
  flex-direction: row;
  display: -ms-flexbox;
  display: flex;
}

@media (width >= 640px) {
  .social ul {
    -ms-flex-direction: column;
    flex-direction: column;
  }
}

.social ul:after {
  content: "";
  clear: both;
  display: block;
}

.social li {
  margin-top: .5rem;
  margin-bottom: .5rem;
  margin-left: 1rem;
}

.social a {
  --tw-text-opacity: 1;
  color: rgba(39, 39, 42, var(--tw-text-opacity));
}

@media (width >= 640px) {
  .social a {
    --tw-text-opacity: 1;
    color: rgba(228, 228, 231, var(--tw-text-opacity));
  }
}

.social a svg {
  height: 1.25rem;
}

.social a svg#email {
  height: 1.1rem;
}
/*# sourceMappingURL=index.78ebadd7.css.map */
